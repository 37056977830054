<template>
  <div class="page">
    <img v-if="honorBanner" class="ny-img" :src="honorBanner" />
    <div class="ny_main">
      <div class="ny_left">
        <div class="ny_lft">
          资质荣誉
        </div>
        <div class="ny_menu">
          <ul>
            <li><a href="/honor?type=zz" :class="type == 'zz' ? 'active':''">资质证书</a></li>
            <li><a href="/honor?type=ry" :class="type == 'ry' ? 'active':''">荣誉证书</a></li>
          </ul>
        </div>
      </div>
      <div class="ny_right">
        <div class="ny_rtt">
          <span>荣誉资质</span>
        </div>
        <div class="ny_rtm">
          <img v-for="item in certificates" :key="item.id" :src="item.picUrl" />          
        </div>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<script>
const WEBAPI = require("apifm-webapi");
WEBAPI.init("gavin");
export default {
  name: "Honor",
  data() {
    return {
      honorBanner: undefined,
      certificates: []
    };
  },
  mounted() {
    this.type = this.$route.query.type
    this.$store.commit("setNavPos", 'honor');    
    this.fetchConfigs();
    this.fetchCertificates();
  },
  methods: {
    async fetchConfigs(){
      const res = await WEBAPI.queryConfigBatch('honorBanner')
      if (res.code == 0) {
        res.data.forEach(ele => {
          if (ele.key == 'honorBanner') {
            this.honorBanner = ele.value
          }
        })
      }
    },
    async fetchCertificates() {
      const res = await WEBAPI.banners({ type: this.type });
      if (res.code == 0) {
        this.certificates = res.data;
      }
    },
  }
};
</script>
<style>
.ny-img {
  width: 100%;
  height: auto;
}
.ny_main {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 40px 0px;
}
.ny_left {
  width: 240px;
  float: left;
}
.ny_lft{width:180px; height:60px; line-height:60px;font-size:16px; font-weight:bold; float:left; color:#fff; background:url(/images/ny_t_bg.jpg) no-repeat left center; padding:0 30px;}
.ny_menu{width:238px;float:left; margin-bottom:20px; border:1px solid #ddd; border-top:none 0;border-bottom:none 0;}
.ny_menu ul{overflow:hidden;}
.ny_menu li{width:180px; height:50px; line-height:50px;  float:left;  padding:0 30px;border-bottom:1px solid #ddd;}
.ny_menu li a{ height:50px; display:block; font-weight:bold; color:#666; font-size:14px;background: url(/images/menu_bg.jpg) right center no-repeat;}
.ny_menu li a:hover,.ny_menu li a.active{color:#ff9900;}
.ny_right{width:818px; float:right; background:#fff; border:1px solid #ddd;}
.about_img{margin-top:20px; float:left;}
.ny_rtt{width:100%; height:60px; line-height:60px; border-bottom:#ddd 1px solid; background:#eee;}
.ny_rtt span{font-size:16px; font-weight:bold; float:left;color:#3f92fa; margin-left:30px;}
.ny_rtt .rt{float:right; color:#666; font-size:14px; margin-right:30px;}
.ny_rtt .rt a{color:#666; font-size:14px;}
.ny_rtm{width:760px; line-height:28px;padding:30px 29px 60px 29px; overflow:hidden;text-align: center;}
.ny_rtm img {
  width: 85%;
}
</style>
