<template>
  <div class="page">
    <img v-if="categoryDetail.icon" class="ny-img" :src="categoryDetail.icon" />
    <div class="ny_main">
      <div class="ny_left">
        <div class="ny_lft">
          {{categoryDetail.name}}
        </div>
        <div class="ny_menu">
          <ul>
            <li v-for="item in articles" :key="item.id"><a :href="'/concat?cid='+ cid +'&id=' + item.id" :class="item.id == id ? 'active':''"> {{item.title}}</a></li>
          </ul>
        </div>
      </div>
      <div class="ny_right">
        <div class="ny_rtt">
          <span>{{articleDetail.title}}</span>
        </div>
        <div class="ny_rtm" v-html="articleDetail.content"></div>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<script>
const WEBAPI = require("apifm-webapi");
WEBAPI.init("gavin");
export default {
  name: "Concat",
  data() {
    return {
      id: undefined,
      categoryId: undefined,
      categoryDetail: {},
      articleDetail: {},
      articles: []
    };
  },
  mounted() {
    this.id = this.$route.query.id
    this.cid = this.$route.query.cid
    this.fetchArticleDetail();    
  },
  methods: {
    async fetchArticleDetail() {
      const res = await WEBAPI.cmsArticleDetail(this.id);
      if (res.code == 0) {
        this.categoryId = res.data.categoryId
        this.articleDetail = res.data
        this.$store.commit("setNavPos", res.data.income);
        if (this.categoryId) {
          this.fetchCmsCategoryDetail()
          this.fetchArticles()          
        }
      }
    },
    async fetchArticles() {
      const res = await WEBAPI.cmsArticles({categoryId: this.categoryId});
      if (res.code == 0) {
        this.articles = res.data
      }
    },
    async fetchCmsCategoryDetail() {
      const res = await WEBAPI.cmsCategoryDetail(this.categoryId);
      if (res.code == 0) {
        this.categoryDetail = res.data
      }
    },
  }
};
</script>
<style>
.ny-img {
  width: 100%;
  height: auto;
}
.ny_main {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 40px 0px;
}
.ny_left {
  width: 240px;
  float: left;
}
.ny_lft{width:180px; height:60px; line-height:60px;font-size:16px; font-weight:bold; float:left; color:#fff; background:url(/images/ny_t_bg.jpg) no-repeat left center; padding:0 30px;}
.ny_menu{width:238px;float:left; margin-bottom:20px; border:1px solid #ddd; border-top:none 0;border-bottom:none 0;}
.ny_menu ul{overflow:hidden;}
.ny_menu li{width:180px; height:50px; line-height:50px;  float:left;  padding:0 30px;border-bottom:1px solid #ddd;}
.ny_menu li a{ height:50px; display:block; font-weight:bold; color:#666; font-size:14px;background: url(/images/menu_bg.jpg) right center no-repeat;}
.ny_menu li a:hover,.ny_menu li a.active{color:#ff9900;}
.ny_right{width:818px; float:right; background:#fff; border:1px solid #ddd;}
.about_img{margin-top:20px; float:left;}
.ny_rtt{width:100%; height:60px; line-height:60px; border-bottom:#ddd 1px solid; background:#eee;}
.ny_rtt span{font-size:16px; font-weight:bold; float:left;color:#3f92fa; margin-left:30px;}
.ny_rtt .rt{float:right; color:#666; font-size:14px; margin-right:30px;}
.ny_rtt .rt a{color:#666; font-size:14px;}
.ny_rtm{width:760px; line-height:28px;padding:30px 29px 60px 29px; overflow:hidden;}
</style>
