<template>
  <div class="page">
    <img v-if="categoryDetail.icon" class="ny-img" :src="categoryDetail.icon" />
    <div class="ny_main">
      <div class="ny_left">
        <div class="ny_lft">
          {{categoryDetail.name}}
        </div>
        <div class="ny_menu">
          <ul>
            <li v-for="item in categories" :key="item.id"><a :href="'/news?cid='+ cid +'&id=' + item.id" :class="item.id == id ? 'active':''"> {{item.name}}</a></li>
          </ul>
        </div>
      </div>
      <div class="ny_right">
        <div class="ny_rtt">
          <span>{{cmsArticleDetail.title}}</span>
        </div>
        <div class="ny_rtm" v-html="cmsArticleDetail.content"></div>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<script>
const WEBAPI = require("apifm-webapi");
WEBAPI.init("gavin");
export default {
  name: "Home",
  data() {
    return {
      cid: undefined,
      id: undefined,
      categoryId: undefined,
      categoryDetail: {},
      articleDetail: {},
      articles: [],
      categories: []
    };
  },
  mounted() {
    this.$store.commit("setNavPos", 'dongtai');

    this.id = this.$route.query.id
    this.cid = this.$route.query.cid
    this.aid = this.$route.query.aid
    this.fetchCmsCategoryDetail()  
    this.cmsCategories()  
    this.cmsArticleDetail()  
  },
  methods: {
    async cmsArticleDetail() {
      const res = await WEBAPI.cmsArticleDetail(this.aid);
      if (res.code == 0) {
        this.cmsArticleDetail = res.data
      }
    },
    async fetchCmsCategoryDetail() {
      const res = await WEBAPI.cmsCategoryDetail(this.cid);
      if (res.code == 0) {
        this.categoryDetail = res.data
      }
    },
    async cmsCategories() {
      const res = await WEBAPI.cmsCategories();
      if (res.code == 0) {
        this.categories = res.data.filter(ele => { return ele.pid == this.cid })
        this.cateGorySmall = res.data.find(ele => { return ele.id == this.id })
      }
    },
  }
};
</script>
<style>
.ny-img {
  width: 100%;
  height: auto;
}
.ny_main {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 40px 0px;
}
.ny_left {
  width: 240px;
  float: left;
}
.ny_lft{width:180px; height:60px; line-height:60px;font-size:16px; font-weight:bold; float:left; color:#fff; background:url(/images/ny_t_bg.jpg) no-repeat left center; padding:0 30px;}
.ny_menu{width:238px;float:left; margin-bottom:20px; border:1px solid #ddd; border-top:none 0;border-bottom:none 0;}
.ny_menu ul{overflow:hidden;}
.ny_menu li{width:180px; height:50px; line-height:50px;  float:left;  padding:0 30px;border-bottom:1px solid #ddd;}
.ny_menu li a{ height:50px; display:block; font-weight:bold; color:#666; font-size:14px;background: url(/images/menu_bg.jpg) right center no-repeat;}
.ny_menu li a:hover,.ny_menu li a.active{color:#ff9900;}
.ny_right{width:818px; float:right; background:#fff; border:1px solid #ddd;}
.about_img{margin-top:20px; float:left;}
.ny_rtt{width:100%; height:60px; line-height:60px; border-bottom:#ddd 1px solid; background:#eee;}
.ny_rtt span{font-size:16px; font-weight:bold; float:left;color:#3f92fa; margin-left:30px;}
.ny_rtt .rt{float:right; color:#666; font-size:14px; margin-right:30px;}
.ny_rtt .rt a{color:#666; font-size:14px;}
.ny_rtm{width:760px; line-height:28px;padding:30px 29px 60px 29px; overflow:hidden;}

.news_bdx{width:100%; float:left; margin-top:10px;}
.news_bdx ul{overflow:hidden; text-indent:0;}
.news_bdx li{width:100%; height:32px; border-bottom:#ddd 1px dashed; float:left;}
.news_bdx li a{ line-height:32px; padding-left:12px; float:left;  background:url(/images/news_tt.jpg) no-repeat left center; color:#666;}
.news_bdx li a:hover{color:#ff9900;}
.news_bdx li span{color:#999; line-height:30px; padding-right:12px; float:right; font-size:12px;}
</style>
